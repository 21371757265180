* {
	box-sizing: border-box;
}

*::-webkit-scrollbar {
	width: calc(3px + 4px * 2);
	height: calc(3px + 4px * 2);
}

*::-webkit-scrollbar-thumb {
	background: #0E0F19;
	height: 3px;
	width: 3px;
	border: 4px solid transparent;
	border-radius: 10px;
	background-clip: content-box;
}

html {
	font-size: 12px;
	background: #F5F5F7;
}

html, body {
	margin: 0;
	font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	overflow: hidden;
	height: 100%;
	height: -webkit-fill-available;
	overscroll-behavior: none;
}

/* hide arrows on input type=number */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

/* dirty fix for google places autocomplete dropdown: https://github.com/ErrorPro/react-google-autocomplete/issues/151 */
div.pac-container {
	z-index: 99999999999 !important;
	margin-top: 10px !important;
}

